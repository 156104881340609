import { ApplicationInsights } from '@microsoft/applicationinsights-web'


type AppInsightsConfig = {
    analyticsEnabled: () => boolean;
    key: string;
}
const aiConfig: AppInsightsConfig = window['AppInsights'] || {analyticsEnabled: () => false, key: ''};
let appInsights;
if(aiConfig.analyticsEnabled()) {
    appInsights = new ApplicationInsights({
        config: {
            instrumentationKey: aiConfig.key,
            disableFetchTracking: false
        }
    });
    appInsights.loadAppInsights();
    appInsights.trackPageView();
    // eslint-disable-next-line no-console
    console.info('Analytics reporting enabled');
}
else {
    // eslint-disable-next-line no-console
    console.warn('Analytics reporting disabled');
}

export default (appInsights as ApplicationInsights)
